import Image from 'next/image';
import Link from 'next/link';

import { ADDRESS } from '@lib/constants';

import logo from '../../public/images/Footer logo-12.svg';

export function Footer() {
  return (
    <footer className="m-auto flex min-h-[364px] flex-col items-center justify-between gap-[100px] bg-blue-lightest p-12 text-blue md:flex-row md:px-[50px] md:py-0">
      <Image src={logo} alt="Monk Lawyers logo" width={210} />
      <address className="text-center text-base font-bold not-italic md:text-left">
        {ADDRESS.street1}
        <br />
        <Link
          className="clickable text-blue before:content-none"
          href={`tel:${ADDRESS.phone}`}
        >
          {ADDRESS.phone}
        </Link>
        <br />
        <Link
          className="clickable text-blue before:content-none"
          href={`tel:${ADDRESS.mobile}`}
        >
          {ADDRESS.mobile}
        </Link>
        <br />
        <Link
          className="clickable text-blue before:content-none"
          href={`mailto:${ADDRESS.email}`}
        >
          {ADDRESS.email}
        </Link>
      </address>
    </footer>
  );
}
